import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { RouteComponentProps } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";
import Gallery from "../../components/Gallery/Gallery";

const items = [
  {
    name: "Random Name 1",
    description: "Probably the most random thing you have ever seen!",
    src: "https://source.unsplash.com/3Z70SDuYs5g/800x600",
  },
  {
    name: "Random Name 2",
    description: "Hello World!",
    src: "https://source.unsplash.com/01vFmYAOqQ0/800x600",
  },
];

function Item(props: any) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <img
        onClick={() => {
          console.log("click");
          props.toggleFullscreen();
        }}
        src={props.item.src}
        style={{ width: "100%" }}
        alt={"test"}
      />
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </Paper>
  );
}

export const Images: React.FunctionComponent<RouteComponentProps<{
  id: string;
}>> = (props) => {
  const [fullscreen, setFullScreen] = useState(false);

  return (
    <>
      <h1>Images</h1>
      <ul>
        <li>
          This should show all the images currently stored in the database for
          the current patient.
        </li>
        <li>Option to filter type of image?</li>
        <li>Clicking on the image should show the image full screen.</li>
        <li>Should provide an option to use full screen carousel.</li>
      </ul>
      <div style={{ width: fullscreen ? "100vw" : "40%", height: "auto" }}>
        <Carousel autoPlay={false}>
          {items.map((item, i) => (
            <Item
              key={i}
              item={item}
              toggleFullscreen={() => {
                console.log("setting fullscreen");
                if (fullscreen) setFullScreen(false);
                else setFullScreen(true);
              }}
            />
          ))}
        </Carousel>
      </div>
      {/*<Gallery />*/}
    </>
  );
};
