import React, { ElementType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLoggedIn } from "./config/accessToken";
interface Props extends RouteProps {
  restricted: boolean;
}
const PublicRoute = ({ component: Component, restricted, ...rest }: Props) => {
  const CastedComponent = Component as ElementType;
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() && restricted ? (
          <Redirect to="/home" />
        ) : (
          <CastedComponent {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
