import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { AppointmentsPatientId } from "../../components/Appointments/AppointmentsPatientId";
import { BasicInformation } from "../../components/Patient/BasicInformation";

import OverviewForPatient from "../../components/Invoice/OverviewForPatient";

// TODO Optimizations to update only different values?
export const Patient: React.FC<RouteComponentProps<{ patientId: string }>> = ({
  match,
}) => {
  const patientId = match.params.patientId;
  return (
    <>
      <h1>Persoonlijke pagina van patiënt</h1>
      <ul>
        <li>
          <Link to={`/patient/report/${patientId}`}>Verslag</Link>
        </li>
        <li>
          <Link to={`/patient/diagnosis/${patientId}`}>Fiche</Link>
        </li>
        <li>
          <Link to={`/patient/${patientId}/financial`}>Financieel plan</Link>
        </li>
      </ul>
      <BasicInformation patientId={patientId} />
      <AppointmentsPatientId patientId={patientId} />
      <OverviewForPatient patientId={patientId} />
    </>
  );
};
