import React from "react";
import { UiSchema } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/material-ui";
import { useRegisterMutation } from "../../generated/graphql";
import { RouteComponentProps } from "react-router-dom";

const uiSchema: UiSchema = {
  "ui:order": ["userName", "password"],
  password: {
    "ui:widget": "password",
  },
};

const patientSchema: JSONSchema7 = {
  title: "A registration form",
  type: "object",
  required: ["userName", "password"],
  properties: {
    userName: {
      type: "string",
      title: "Username",
    },
    password: {
      type: "string",
      title: "Password",
    },
  },
};

export const Register: React.FC<RouteComponentProps> = ({ history }) => {
  const [registerUser, { data, loading }] = useRegisterMutation();
  function register(login: string, password: string) {
    registerUser({ variables: { login, password } });
  }

  if (data) {
    console.log("Login succesful... Redirecting");
    history.push("/");
  }
  return (
    <div className="App">
      <header className="App-header">
        <div>Register new user</div>
        {!data && (
          <Form
            disabled={loading}
            schema={patientSchema}
            uiSchema={uiSchema}
            onSubmit={({
              formData,
            }: {
              formData: { userName: string; password: string };
            }) => {
              register(formData.userName, formData.password);
            }}
          />
        )}
      </header>
    </div>
  );
};
