import React, { useRef, useState } from "react";
import "./App.css";

import EmailEditor, { MergeTag } from "react-email-editor";
import { Button } from "@material-ui/core";
const options = [
  {
    label: "Voornaam patient",
    value: "fname",
    sample: "Jan",
  },
  { label: "Achternaam patient", value: "name", sample: "Modaal" },
];

const mergeTags: MergeTag[] = [
  {
    name: "Voornaam patient",
    value: "{{fname}}",
    // @ts-ignore
    sample: "Lalala",
  },
  { name: "Achternaam patient", value: "{{name}}" },
];

const Email = () => {
  const emailEditorRef = useRef<any>(null);
  const [output, setOutput] = useState("");

  const exportHtml = () => {
    if (emailEditorRef.current)
      emailEditorRef.current.editor.exportHtml((data: any) => {
        const { design, html } = data;
        setOutput(html);
      });
  };

  const onLoad = () => {
    // you can load your template here;
    const templateJson = {
      counters: {
        u_column: 1,
        u_row: 1,
        u_content_text: 1,
        u_content_custom_my_tool: 1,
      },
      body: {
        rows: [
          {
            cells: [1],
            columns: [
              {
                contents: [
                  {
                    type: "text",
                    values: {
                      containerPadding: "10px",
                      _meta: {
                        htmlID: "u_content_text_1",
                        htmlClassNames: "u_content_text",
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      color: "#000000",
                      textAlign: "left",
                      lineHeight: "140%",
                      linkStyle: {
                        inherit: true,
                        linkColor: "#0000ee",
                        linkHoverColor: "#0000ee",
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      hideMobile: false,
                      text:
                        '<span style="font-size: 14px; line-height: 19.6px;">This is a new Text block. Change the text.</span>',
                    },
                  },
                  {
                    type: "custom",
                    slug: "my_tool",
                    values: {
                      containerPadding: "10px",
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      variables: "fname",
                      hideDesktop: false,
                      hideMobile: false,
                      _meta: {
                        htmlID: "u_content_custom_my_tool_1",
                        htmlClassNames: "u_content_custom_my_tool",
                      },
                    },
                  },
                ],
                values: {
                  backgroundColor: "",
                  padding: "0px",
                  border: {},
                  _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: "",
              columnsBackgroundColor: "",
              backgroundImage: {
                url: "",
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: "0px",
              hideDesktop: false,
              hideMobile: false,
              noStackMobile: false,
              _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#e7e7e7",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          contentWidth: "500px",
          fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
          linkStyle: {
            body: true,
            linkColor: "#0000ee",
            linkHoverColor: "#0000ee",
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
        },
      },
      schemaVersion: 5,
    };
    if (emailEditorRef.current)
      emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const saveDesign = () => {
    if (emailEditorRef.current)
      emailEditorRef.current.editor.saveDesign((design: any) => {
        console.log(typeof design);
        setOutput(JSON.stringify(design));
      });
  };

  return (
    <div>
      <p>{output}</p>
      <Button
        onClick={() => {
          exportHtml();
        }}
      >
        HTML
      </Button>
      <Button
        onClick={() => {
          saveDesign();
        }}
      >
        DESIGN
      </Button>
      <EmailEditor
        projectId={1071}
        ref={emailEditorRef}
        onLoad={onLoad}
        options={{
          // editor: { autoSelectOnDrop: true },
          tools: {
            // my_tool is the name of our custom tool
            // It is required to add custom# before the name of your custom tool
            "custom#my_tool": {
              data: {
                options: JSON.stringify(options),
              },
              // @ts-ignore
              properties: {
                // occupation is the name of our property
                variables: {
                  editor: {
                    data: {
                      options,
                    },
                  },
                },
              },
            },
          },
          displayMode: "email",
          customJS: [
            window.location.protocol +
              "//" +
              window.location.host +
              "/custom.js",
          ],
          mergeTags,
        }}
      />
    </div>
  );
};

export default Email;
