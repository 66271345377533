import React, { useEffect, useState } from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { useAppApolloClient } from "./config/apolloClient";
import { Routes } from "./Routes";
import { getAccessToken, setAccessToken } from "./config/accessToken";
import { CircularProgress } from "@material-ui/core";
import { useAsyncError } from "./ErrorHandling/useAsyncError";
import { RecoilRoot, atom } from "recoil";

export const billsPaidState = atom({
  key: "allBillsPaid", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

function App() {
  const apolloClient = useAppApolloClient();
  const [loading, setLoading] = useState(true);
  const throwError = useAsyncError();

  // TODO Move this to seperate service
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}refresh_token`, {
      method: "POST",
      credentials: "include",
    })
      .then(async (x) => {
        const { accessToken } = await x.json();
        setAccessToken(accessToken);
        setLoading(false);
      })
      .catch((e) => {
        throwError(`Problem with fetching new token:\n\n${e}`);
      });
  }, [throwError]);

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: " 50%",
          transform: "translateX(-50%) translateY(-50%)",
          textAlign: "center",
        }}
      >
        <CircularProgress style={{ color: "orangered" }} />
        <p>Refreshing token...</p>
      </div>
    );

  return (
    <ApolloProvider client={apolloClient}>
      <RecoilRoot>
        <Routes />
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default App;
