import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  Appointment,
  useCreateNewAppointmentForPatientMutation,
  useGetAppointmentProposalsQuery,
} from "../../generated/graphql";
import { JSONSchema7 } from "json-schema";
import Form from "../Forms/Form";
import { AutoCompleteSearchPatientWidget } from "../Forms/JsonSchemaFormWidgets/AutoCompleteSearchPatientWidget";
import { AutoCompleteAppointmentProposalWidget } from "../Forms/JsonSchemaFormWidgets/AutoCompleteAppointmentProposalWidget";
import dateFormat from "dateformat";
import { AutoCompleteCalendarWidget } from "../Forms/JsonSchemaFormWidgets/AutoCompleteCalendarWidget";
import { WidgetProps } from "@rjsf/core";
import DateTimePickerWidget from "../Forms/JsonSchemaFormWidgets/DateTimePickerWidget";
import TimePickerWidget from "../Forms/JsonSchemaFormWidgets/TimePickerWidget";
import { APPOINTMENTS_BETWEEN_TWO_DATES } from "../../queries";
import { endDate, startDate } from "../Calendar";
export interface RawAppointment {
  allDay: boolean;
  start: any;
  end: any;
}

interface Props {
  open: boolean;
  handleClose: (event?: any) => void;
  appointment: RawAppointment;
}

interface Formdata {
  proposal: boolean;
  title?: string;
  description?: string;
  patientId?: string;
  linkedProposal?: string;
  timeEnd: string;
  timeStart: string;
}

const linkedApp: any = {
  linkedProposal: {
    type: ["string", "null"],
    title: "Voorstel",
    default: null,
  },
};

const NewAppointmentOrProposal: any = {
  patientId: {
    type: "string",
    title: "Patient",
  },
  calendar: {
    type: "string",
    title: "Kleurcode - agenda",
  },
  title: {
    title: "Titel",
    type: "string",
    default: "",
    description: "Titel krijgt automatisch de naam toegevoegd",
  },
  description: {
    type: "string",
    title: "Beschrijving",
    default: "",
    description: "Beschrijving bevat automatisch identificatie van afspraak! ",
  },
};
const NewAppointmentFromTimeDialog = ({
  open,
  handleClose,
  appointment,
}: Props) => {
  const [
    createMutation,
    creationResult,
  ] = useCreateNewAppointmentForPatientMutation({
    refetchQueries: [
      {
        query: APPOINTMENTS_BETWEEN_TWO_DATES,
        variables: { startDate: startDate.toDate(), endDate: endDate.toDate() },
      },
    ],
  });

  const [formData, setFormdata] = useState<Formdata | null>(null);
  const { data, loading, refetch } = useGetAppointmentProposalsQuery();

  useEffect(() => {
    if (appointment) {
      console.log(appointment);
      setFormdata({
        timeEnd: appointment.end ? appointment.end.toISOString() : undefined,
        proposal: false,
        timeStart: appointment.start
          ? appointment.start.toISOString()
          : undefined,
      });
    }
  }, [appointment]);

  const schema: JSONSchema7 = {
    type: "object",
    required:
      // It is required to fill in a title and description when its
      // not a proposal or when it does not have a linked proposal
      formData?.proposal && formData?.linkedProposal
        ? []
        : (["patientId", "calendar"] as string[]),
    properties: {
      // TODO enkel tijd en naast elkaar
      timeStart: {
        type: "string",
        title: "Start",
        // default: appointment.start,
      },
      timeEnd: {
        type: "string",
        title: "Einde",
        // default: appointment.end,
      },
      proposal: {
        type: "boolean",
        title: "Voorstel",
        default: false,
      },
      ...(formData?.proposal ? linkedApp : {}),
      // if its not a new proposal or appointment, don't show appointment info
      ...(formData?.proposal && formData?.linkedProposal
        ? {}
        : NewAppointmentOrProposal),
    },
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Maak nieuwe afspraak"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {open && (
            <Typography>
              {appointment.allDay
                ? `${dateFormat(
                    appointment.start,
                    "dd/mm/yyyy"
                  )} - ${dateFormat(appointment.end, "dd/mm/yyyy")}`
                : `${dateFormat(
                    appointment.start,
                    "dd/mm/yyyy HH:MM"
                  )} - ${dateFormat(appointment.end, "HH:MM")}`}
            </Typography>
          )}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          <p>{JSON.stringify(formData, null, 2)}</p>
          {open && (
            <Form
              formData={
                formData?.linkedProposal
                  ? { ...formData, patientId: undefined }
                  : (formData as any)
              }
              onChange={({ formData }) => setFormdata(formData as any)}
              uiSchema={{
                "ui:order": [
                  "timeStart",
                  "timeEnd",
                  "proposal",
                  "linkedProposal",
                  "*",
                ],
                timeStart: {
                  "ui:widget": TimePickerWidget,
                  "ui:column": 6,
                },
                timeEnd: {
                  "ui:widget": TimePickerWidget,
                  "ui:column": 6,
                },
                patientId: {
                  "ui:widget": AutoCompleteSearchPatientWidget,
                },
                linkedProposal: {
                  "ui:widget": (props: WidgetProps) => (
                    <AutoCompleteAppointmentProposalWidget
                      {...props}
                      loading={loading}
                      data={data?.appointmentProposals ?? []}
                    />
                  ),
                },
                calendar: {
                  "ui:widget": AutoCompleteCalendarWidget,
                },
              }}
              onSubmit={async ({
                formData,
              }: {
                formData: {
                  title: string;
                  timeStart: string;
                  timeEnd: string;
                  description: string;
                  patientId: string;
                  proposal: boolean;
                  linkedProposal: string;
                  calendar?: string;
                };
              }) => {
                console.log(appointment);
                const { data } = await createMutation({
                  variables: {
                    input: {
                      // Must be a date to work...
                      dateForAppointmentStart: new Date(formData.timeStart),
                      dateForAppointmentEnd: new Date(formData.timeEnd),
                      title: formData.title,
                      description: formData.description,
                      allDay: appointment.allDay,
                      proposalId: formData.linkedProposal,
                      proposal: formData.proposal,
                      calendarId: formData.calendar,
                      // confirmed: formData.confirmed,
                      patientId: formData.patientId,
                    },
                  },
                });

                if (data) {
                  setFormdata(null);
                  refetch();
                  handleClose({
                    // ...data,
                    ...appointment,
                    id: data.createNewAppointmentForPatient?.id,
                    summary: formData.description,
                    title: formData.title,
                  });
                }
              }}
              schema={schema}
            >
              <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                  Annuleer
                </Button>
                <Button type={"submit"} color="primary">
                  Bevestig
                </Button>
              </DialogActions>
            </Form>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
    // <GenericDialog
    //   handleClose={() =>
    //     handleClose({ ...appointment, id: 30, title: "Titel" })
    //   }
    //   open={open}
    //   hookMutation={useCreateNewAppointmentForPatientMutation}
    //   schema={{
    //     type: "object",
    //     properties: {
    //       test: { type: "string", title: "Test" },
    //     },
    //   }}
    // />
  );
};

export default NewAppointmentFromTimeDialog;
