import { Grid } from "@material-ui/core";
import React, { ErrorInfo } from "react";
import Button from "@material-ui/core/Button";

interface Props {
  fallback: React.ReactNode;
}

interface State {
  error: boolean;
  errorMessage?: string[];
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: false };

  static getDerivedStateFromError(error: Error) {
    return {
      error: !!error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log or store the error
    //console.error(error);
    this.setState({
      errorMessage: [error.toString(), errorInfo.componentStack],
    });
  }

  render() {
    return this.state.error ? (
      <Grid container style={{ padding: "3em" }}>
        <Grid item sm={12}>
          {this.props.fallback}
        </Grid>
        <Grid item>
          {this.state.errorMessage?.map((e) => (
            <p>{e}</p>
          ))}
          <Button
            href={`mailto:diederikdeconinck@icloud.com?&subject=Probleem%20met%20platform%20&body=${encodeURI(
              this.state.errorMessage?.join("\n\n") ||
                "Beschrijf hier zo goed mogelijk wat er mis ging..."
            )}`}
            variant={"contained"}
            color={"primary"}
          >
            Verstuur email voor foutenoplossing
          </Button>
          <Button
            variant={"contained"}
            color={"default"}
            onClick={() => this.setState({ error: false })}
          >
            Sluit en ga terug
          </Button>
        </Grid>
      </Grid>
    ) : (
      this.props.children
    );
  }
}
