import React, { useEffect } from "react";
import { UiSchema } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/material-ui";
import { useLoginMutation } from "../../generated/graphql";
import { RouteComponentProps } from "react-router-dom";
import { setAccessToken } from "../../config/accessToken";
import { ME_QUERY } from "../../queries";

const uiSchema: UiSchema = {
  "ui:order": ["userName", "password"],
  password: {
    "ui:widget": "password",
  },
};

const patientSchema: JSONSchema7 = {
  title: "A Login form",
  type: "object",
  required: ["userName", "password"],
  properties: {
    userName: {
      type: "string",
      title: "Username",
    },
    password: {
      type: "string",
      title: "Password",
    },
  },
};

export const Login: React.FC<RouteComponentProps> = ({ history, ...props }) => {
  const [signInCall, { data, loading, client }] = useLoginMutation({
    refetchQueries: [{ query: ME_QUERY }],
  });

  async function signIn(login: string, password: string) {
    await signInCall({ variables: { login, password } });
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  function redirect() {
    const { location } = props;
    const state = location.state as { from: string };
    let redirectTo = "/";
    if (state && state.from) {
      redirectTo = state.from;
    }
    history.push(redirectTo);
  }

  if (data) {
    setAccessToken(data.signIn.accessToken);
    // TODO Better way to do this? Otherwise error transition react state
    setTimeout(() => redirect(), 500);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>Login op admin</div>
        {!data && (
          <Form
            disabled={loading}
            schema={patientSchema}
            uiSchema={uiSchema}
            onSubmit={async ({
              formData,
            }: {
              formData: { userName: string; password: string };
            }) => {
              await signIn(formData.userName, formData.password);
            }}
          />
        )}
        {data && <div>Succesvol ingelogd... Doorsturen naar homepagina</div>}
      </header>
    </div>
  );
};
