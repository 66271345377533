import { Button } from "@material-ui/core";
import React from "react";
import { useLogoutMutation } from "../generated/graphql";
import { setAccessToken } from "../config/accessToken";
import { useHistory } from "react-router-dom";
const LogoutBtn = () => {
  const [logout] = useLogoutMutation();
  const history = useHistory();
  return (
    <Button
      color="secondary"
      variant={"contained"}
      onClick={async () => {
        await logout(); // clear refresh token
        setAccessToken(""); // clear access token
        // await client!.resetStore(); // TODO this makes apollo refetch...

        history.push("/login");
      }}
    >
      Logout
    </Button>
  );
};
export default LogoutBtn;
