import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CssBaseline } from "@material-ui/core";
import { ErrorBoundary } from "./ErrorHandling/ErrorBoundary";

export function isDevMode(){
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
}
ReactDOM.render(
  <React.StrictMode>
    <>
      <ErrorBoundary fallback={<h1>Oeps, er ging iets mis!</h1>}>
        <CssBaseline />
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ErrorBoundary>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
