import React, { ElementType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { isLoggedIn } from "./config/accessToken";

interface Props extends RouteProps {}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const CastedComponent = Component as ElementType;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Header>
            <CastedComponent {...props} />
          </Header>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
