import React, { useState } from "react";
import {
  useAppointmentsForPatientQuery,
  useCancelAppointmentMutation,
  useUpdateAppointmentByIdMutation,
} from "../../generated/graphql";
import {
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { GenericDialog, GenericTable } from "../Tables/GenericSelectTable";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import dateFormat from "dateformat";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

interface Props {
  patientId: string;
}

function isBetweenSchoolTimes(date: Date) {
  // TODO Vacation
  let hours = date.getHours();
  let day = date.getDay();
  let openingDays = [0, 1, 2, 3, 4];
  let vacationMonths = [6, 7];
  return (
    !vacationMonths.includes(date.getMonth()) &&
    openingDays.includes(day) &&
    hours >= 8 &&
    hours <= 15.5
  );
}

export const AppointmentsPatientId = ({ patientId }: Props) => {
  const { data, loading } = useAppointmentsForPatientQuery({
    variables: { patientId },
  });
  const [cancelAppointment, resCancel] = useCancelAppointmentMutation();
  const [cancelDialogId, setCancelDialogId] = useState<string | undefined>();
  const [appId, setAppId] = useState<undefined | string>();
  const history = useHistory();
  function redirectToAppointmentsPage(id: string, _: any) {
    history.push(`/appointment/${id}`);
  }
  if (loading)
    return (
      <div>
        <p>Loading data...</p>
        <CircularProgress />
      </div>
    );
  return (
    <>
      <h1>Afspraken</h1>
      <p>
        Betaling ook mededeling laten zien; risiv nr weergeven in tabel +
        dropdown om deze in te vullen; als afspraak afgezegd wordt binnen
        kwartier, verwijder deze dan permanent; rode balk = afspraak niet
        gekomen; geanulleerd? kruisje grijs
      </p>
      <GenericDialog
        handleClose={() => setAppId(undefined)}
        open={!!appId}
        hookMutation={useUpdateAppointmentByIdMutation}
        mapFormDataToMutationArgs={(formData: { paid: boolean }) => {
          return { variables: { data: { paid: formData.paid }, id: appId } };
        }}
        schema={{
          type: "object",
          properties: {
            paid: { type: "boolean", title: "Betaald?", default: true },
          },
        }}
      >
        <Typography>
          {(() => {
            const appointment = data?.appointmentsForPatient.find(
              (app) => app.id === appId
            );
            if (appointment) {
              return `${dateFormat(
                appointment.start,
                "dd/mm/yyyy HH:MM"
              )} - ${dateFormat(appointment.end, "HH:MM")}\n${
                appointment.patient.person.fname
              } ${appointment.patient.person.name}`;
            } else return "Geen informatie gevonden...";
          })()}
        </Typography>
      </GenericDialog>
      <GenericTable
        sortOnKey={"nb"}
        sort={"desc"}
        getTableRowBackground={(row) => {
          let color = "inherit";
          if (isBetweenSchoolTimes(row.mDate.toDate())) {
            // todo check between school hours
            color = "rgba(160,160,160,0.24)";
          }

          if (row.cancelled) {
            color = "rgba(255,0,0,0.51)";
          }

          return color;
        }}
        rows={
          data?.appointmentsForPatient.map((appointment, index) => {
            const mStart = moment(appointment.start);
            const mEnd = moment(appointment.end);
            return {
              date: mStart.format("DD/MM/yyyy"),
              mDate: mStart,
              time: `${mStart.format("HH:mm")} - ${mEnd.format("HH:mm")}`,
              // paid: appointment.paid,
              cancelled: appointment.cancelled,
              nb: index + 1,
              id: appointment.id,
            };
          }) || []
        }
        labels={[
          {
            id: "nb",
            label: "Volgnummer",
            onTableCellClick: redirectToAppointmentsPage,
          },
          {
            id: "date",
            label: "Datum afspraak",
            onTableCellClick: redirectToAppointmentsPage,
          },
          {
            id: "time",
            label: "Tijdstip",
            onTableCellClick: redirectToAppointmentsPage,
          },
          // {
          //   id: "paid",
          //   label: "Betaling",
          //   renderField: (value, rowId) => (
          //     <div style={{ alignItems: "center", display: "flex" }}>
          //       <CheckIcon
          //         style={{
          //           color: value ? "green" : "lightgray",
          //         }}
          //       />
          //       <Button onClick={() => setAppId(rowId)}>Wijzig status</Button>
          //     </div>
          //   ),
          //   disableSort: true,
          // },
          {
            id: "cancelled",
            label: "Annuleer",
            renderField: (value, rowId) => (
              <Button disabled={value} onClick={() => setCancelDialogId(rowId)}>
                <CancelIcon style={{ color: value ? "grey" : "red" }} />
              </Button>
            ),
          },
        ]}
        // redirect to page for that appointment
        // onRowClick={(id) => history.push(`/appointment/${id}`)}
      />
      <CancelDialog
        handleClose={async (agreed) => {
          if (agreed && cancelDialogId) {
            await cancelAppointment({ variables: { id: cancelDialogId } });
            setCancelDialogId(undefined);
          }
        }}
        open={!!cancelDialogId}
      />
    </>
  );
};

const CancelDialog = ({
  handleClose,
  open,
}: {
  handleClose: (agreed: boolean) => void;
  open: boolean;
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Annuleer deze afspraak"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Je staat op het punt een afspraak te annuleren, ben je zeker?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Sluit
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Annuleer afspraak
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
