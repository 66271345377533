import React from "react";
import { useAppointmentsForPatientHistoryQuery } from "../../generated/graphql";
import {
  CircularProgress,
  GridList,
  GridListTile,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AppointmentCard from "./AppointmentCard";
import dateFormat from "dateformat";
import Grid from "@material-ui/core/Grid";

interface Props {
  patientId: string;
  currentAppointmentId: string;
  currentAppointmentDate: string;
}

const Notes = ({ publicNote }: any) => {
  const parsed = JSON.parse(publicNote);
  return (
    <div style={{ display: "inline-block" }}>
      <Grid container>
        <Grid item sm={6}>
          <h4>Boven</h4>
          <p>{parsed.upper}</p>
        </Grid>
        <Grid item sm={6}>
          <h4>Onder</h4>
          <p>{parsed.lower}</p>
        </Grid>
      </Grid>
    </div>
  );
};
const AppointmentHistory = (props: Props) => {
  const { data, loading } = useAppointmentsForPatientHistoryQuery({
    variables: { patientId: props.patientId },
  });

  if (loading || !data) {
    return <CircularProgress />;
  }

  const mappedData = data.appointmentsForPatient
    // filter to show only history & non nullables
    .filter((value) => value.start < props.currentAppointmentDate)
    // sort to show latest appointments at the top
    .sort((t, o) => (t.start > o.start ? -1 : t.start < o.start ? 1 : 0))
    .map((value) => {
      return (
        <GridListTile cols={1} key={value.id}>
          <AppointmentCard
            title={dateFormat(value.start, "dd/mm/yyyy")}
            lowerSmallTitle={"Notitie:"}
            id={value.id}
          >
            <Link to={`/appointment/${value.id}`}>
              {value.publicNote ? (
                <Notes publicNote={value.publicNote} />
              ) : (
                "Geen notities"
              )}
            </Link>
          </AppointmentCard>
        </GridListTile>
      );
    });
  return (
    <>
      <Typography variant={"h5"}>Vorige afspraken:</Typography>
      {mappedData.length > 0 ? (
        <GridList
          cols={1}
          cellHeight={"auto"}
          style={{
            width: "100%",
            height: 200,
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          spacing={5}
        >
          {mappedData}
        </GridList>
      ) : (
        <p>Geen geschiedenis</p>
      )}
    </>
  );
};
export default AppointmentHistory;
